import { computed } from "vue";

function priorityToNumber(priority) {
  return (
    {
      urgent: 4,
      high: 3,
      medium: 2,
      low: 1
    }[priority] ?? 0
  );
}

const sortingFunctions = {
  estimatedTime: (a, b) => a.estimatedTime - b.estimatedTime,
  priority: (a, b) =>
    priorityToNumber(a.priority) - priorityToNumber(b.priority)
};

export default function useSortedTasks({ tasks, criteria, direction }) {
  const sortingFunction = computed(() => sortingFunctions[criteria.value]);
  const sortingFunctionWithDirection = computed(() => (a, b) =>
    direction.value === "asc"
      ? sortingFunction.value(a, b)
      : sortingFunction.value(b, a)
  );

  const sortedTasks = computed(() =>
    tasks.value.slice().sort(sortingFunctionWithDirection.value)
  );

  return { sortedTasks };
}
